import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import SocialIcons from './socialIcons'
import logoImg from './female_cofounders_logo_white.svg'

const Footer = ({ green }) => {
  return (
    <footer className="bg-gray-500 text-white font-light py-24">
      <div className="container text-center">
        <div className="mb-6 flex justify-center">
          <img src={logoImg} className="h-10" />
        </div>
        <div className="mb-12">
          <a href="mailto:hallo@femalecofounders.de" className="no-mail hover:underline">hallo@femalecofounders.de</a>
        </div>
        <SocialIcons green={green} />
        <div className="text-sm">
          <span>© {new Date().getFullYear()} Female Co-Founders</span>
          &nbsp;| <Link to="/impressum" className="hover:underline">Impressum</Link>
          &nbsp;| <Link to="/datenschutz" className="hover:underline">Datenschutz</Link>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  green: PropTypes.bool
}

Footer.defaultProps = {
  green: false
}

export default Footer
