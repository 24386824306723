import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import logoWhiteImg from './female_cofounders_logo_white.svg'
import logoGreenImg from './female_cofounders_logo_green.svg'
import logoImg from './female_cofounders_logo.svg'
import SocialIcons from './socialIcons'

const Navigation = ({ isAbsolute, inverted, green, currentPage }) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  });
  return (<>
    <div className={`py-10 w-full z-10 hidden md:block ${isAbsolute ? 'absolute' : ''}`}>
      <div className={`container flex items-end h-8 ${inverted ? (green ? 'text-persian-green' : 'text-meteor-500') : 'text-white'}`}>
        <Link to="/" className="hover:opacity-75 h-full">
          <img src={logoImg} className={`${(!inverted || green) ? 'invisible h-0' : 'h-full'}`} alt="Female Co-Founders" />
          <img src={logoWhiteImg} className={`${(inverted || green) ? 'invisible h-0' : 'h-full'}`} alt="Female Co-Founders" />
          <img src={logoGreenImg} className={`${!green ? 'invisible h-0' : 'h-full'}`} alt="Female Co-Founders" />
        </Link>
        <div className="flex flex-grow justify-end items-baseline font-light h-full pt-1">
        <Link to="/co-founder" className={`ml-6 lg:ml-8 xl:ml-10 hover:opacity-50 border-b-2 border-transparent ${currentPage === 'co-founder' && 'border-meteor-500'}`}>Co-Founding</Link>
          <Link to="/events" className={`ml-6 lg:ml-8 xl:ml-10 hover:opacity-50 border-b-2 border-transparent ${currentPage === 'events' && 'border-meteor-500'}`}>Events</Link>
          <Link to="/blog" className={`ml-6 lg:ml-8 xl:ml-10 hover:opacity-50 border-b-2 border-transparent ${currentPage === 'blog' && 'border-meteor-500'}`}>Blog</Link>
          <Link to="/b2b" className={`ml-6 lg:ml-8 xl:ml-10 hover:opacity-50 border-b-2 border-transparent ${currentPage === 'b2b' && 'border-persian-green'}`}>B2B</Link>
          <Link to="/about" className={`ml-6 lg:ml-8 xl:ml-10 hover:opacity-50 border-b-2 border-transparent ${currentPage === 'about' && 'border-meteor-500'}`}>Über uns</Link>

          {!inverted && <Link to="/join" className="ml-6 lg:ml-8 xl:ml-10 hover:opacity-50 text-mulberry-500 bg-white rounded px-2 font-bold leading-none py-1">Join!</Link>}
          {inverted && !green && <Link to="/join" className="ml-6 lg:ml-8 xl:ml-10 hover:opacity-50 text-white bg-meteor-500 bg-gradient-l-mulberry-500 rounded px-2 font-bold leading-none py-1">Join!</Link>}
          {inverted && green && <Link to="/join" className="ml-6 lg:ml-8 xl:ml-10 hover:opacity-50 text-white bg-persian-green bg-gradient-l-ronchi rounded px-2 font-bold leading-none py-1">Join!</Link>}
        </div>
      </div>
    </div>
    {isOpen && <div className="h-12"></div>}
    <div className={`w-full top-0 ${isOpen && 'fixed z-20'}`}>
      <div className={`md:hidden ${green ? 'bg-persian-green bg-gradient-l-ronchi' : 'bg-meteor-500 bg-gradient-l-mulberry-500'} py-3 h-12 w-full box-border`}>
        <div className="container h-full flex content-center">
          <div className="flex-grow">
            <Link to="/" className="h-full">
              <img src={logoWhiteImg} className="h-full" alt="Female Co-Founders" />
            </Link>
          </div>
          <div className="w-6 self-end">
            <button onClick={() => setIsOpen(!isOpen)} className="w-full block outline-none text-white">
              {!isOpen && <>
                <hr className="border-white border-2 box-border w-full mb-1" />
                <hr className="border-white border-2 box-border w-full my-1" />
                <hr className="border-white border-2 box-border w-full mt-1" />
              </>}
              {isOpen && 'X'}
            </button>
          </div>
        </div>
      </div>
      {isOpen && <div className="bg-pampas w-full box-border text-center" style={{ height: 'calc(100vh - 3rem)' }}>
        <div className="flex flex-col h-full">
          <div className="flex-grow justify-center flex flex-col">
            <ul className={`${green ? 'text-persian-green' : 'text-meteor-500'} text-xl font-light`}>
            <li className="my-8"><Link to="/co-founder" className="py-4">Co-Founding</Link></li>
              <li className="mb-8"><Link to="/events" className="py-4">Events</Link></li>
              <li className="my-8"><Link to="/blog" className="py-4">Blog</Link></li>
              <li className="my-8"><Link to="/b2b" className="py-4">B2B</Link></li>
              <li className="my-8"><Link to="/about" className="py-4">Über uns</Link></li>
              <li className="my-8"><Link to="/join" className={`hover:opacity-50 text-white ${green ? 'bg-persian-green bg-gradient-l-ronchi' : 'bg-meteor-500 bg-gradient-l-mulberry-500'} rounded px-4 py-2 font-bold`}>Join!</Link></li>
            </ul>
          </div>
          <div>
            <div className="mb-8">
              <a href="mailto:hello@femalecofounders.de">hello@femalecofounders.de</a>
            </div>
            <SocialIcons bright={!green} green={green} />
          </div>
        </div>
      </div>}
    </div>
  </>)
}

Navigation.propTypes = {
  isAbsolute: PropTypes.bool,
  inverted: PropTypes.bool,
  green: PropTypes.bool,
  currentPage: PropTypes.string
}

Navigation.defaultProps = {
  isAbsolute: true,
  inverted: false,
  green: false
}

export default Navigation
