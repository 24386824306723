import React, { useState } from "react"
import CookieConsent from "react-cookie-consent";
import Cookies from "js-cookie";

function loadGA() {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-163704468-1';
  document.getElementsByTagName('head')[0].appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag(){ window.dataLayer.push(arguments); }
  gtag('js', new Date());

  gtag('config', 'UA-163704468-1');
}

const Consent = () => {
  const [show, setShow] = useState(true);

  if(Cookies.get('only_essential_cookies') === '1' || !show) {
    return null;
  }

  if(Cookies.get('cookie_consent_ga')) {
    loadGA();
  }

  return (
    <CookieConsent
    buttonText="Alle Cookies akzeptieren"
    cookieName="cookie_consent_ga"
    disableStyles
    style={{ background: "rgba(77,77,77,0.85)", zIndex: 40 }}
    buttonClasses="cookie-consent__button"
    onAccept={() => loadGA()}
    expires={150}>
      Wir verwenden Cookies, um Dir die beste Online-Erfahrung zu bieten.
      Mit Deiner Zustimmung akzeptierst Du die Verwendung von Cookies in Übereinstimmung mit unseren Cookie-Richtlinien.
      <div className="pt-2">
        <a href="#" onClick={() => { Cookies.set('only_essential_cookies', '1', { expires: 7 }); setShow(false) }}
          className="text-mulberry-300 border border-mulberry-500 px-2 py-1 rounded-sm hover:border-mulberry-300">
          Nur notwendige Cookies verwenden
        </a>
      </div>
    </CookieConsent>
  )
}

Consent.propTypes = {}

export default Consent
