import React from "react"
import PropTypes from "prop-types"

import Footer from './Footer'
import CookieConsent from './cookieConsent'

import "./layout.scss"

const Layout = ({ children, green }) => {
  return (
    <>
      <CookieConsent />
      <main>{children}</main>
      <Footer green={green} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  green: PropTypes.bool
}

export default Layout
