import React from "react"
import PropTypes from "prop-types"

import logoImg from './female_cofounders_logo_white.svg'
import meetupImg from './social_media/meetup_icon.svg'
import linkedInImg from './social_media/linkedin_icon.svg'
import facebookImg from './social_media/facebook_icon.svg'
import xingImg from './social_media/xing_icon.svg'
import elphaImg from './social_media/e_icon.svg'
import slackIcon from './social_media/slack.png'
import instagramIcon from './social_media/instagram.svg'

const social = {
  meetup: {
    title: "Meetup",
    img: meetupImg,
    url: "https://www.meetup.com/de-DE/Female-Co-Founders/"
  },
  linkedIn: {
    title: "LinkedIn",
    img: linkedInImg,
    url: "https://www.linkedin.com/company/femalecofounders/"
  },
  facebook: {
    title: "Facebook",
    img: facebookImg,
    url: "https://www.facebook.com/FemaleCoFounders/"
  },
  slack: {
    title: "Slack",
    img: slackIcon,
    url: "https://join.slack.com/t/femalecofounders/shared_invite/zt-e5y85n6b-6ufe6~WnstPbmIfIIpoflg"
  },
  instagram: {
    title: "Instagram",
    img: instagramIcon,
    url: "https://www.instagram.com/female.cofounders/"
  }
}

const SocialIcons = ({ bright, green }) => {
  return (
    <div className="mb-16 flex justify-center">
      {Object.values(social).map(data => (
        <a key={data.title} href={data.url} target="_blank" className={`${green ? 'bg-persian-green bg-gradient-l-ronchi' : (bright ? 'bg-meteor-500 bg-gradient-l-mulberry-500' : 'bg-meteor-rusty bg-gradient-l-mulberry-rusty')} hover:opacity-50 rounded-full mx-2 w-12 h-12 flex justify-center p-3`}>
          <img src={data.img} alt={data.title} />
        </a>
      ))}
    </div>
  )
}

SocialIcons.propTypes = {
  green: PropTypes.bool,
  bright: PropTypes.bool
}

SocialIcons.defaultProps = {
  green: false,
  bright: false
}

export default SocialIcons
